<template>
<div class="manage-cont">
  <div class="green-circle-button close-button" @click="closeModal">
    <span>X</span>
  </div>
  <div class="subtitle-page">Staff Receiving Notifications</div>
  <div class="contentbox box-shadow radius">
    <div v-for="staff in this.allStaff" :key="staff._id" class="staff-entry radius">
      <span>{{nameString(staff.user.name)}}</span>
      <div class="btn-green radius small reverse" @click="() => deleteStaff(staff)">Remove</div>
    </div>
    <div class="add-toggle">
      <div class="btn-green radius full" @click="toggleAdd" v-if="!adding">Add New</div>
    </div>
    <div class="add-staff" v-if="adding">
      <v-select
        v-model="newStaff"
        :multiple="false"
        :options="employees"
        placeholder="Employee"
        label="nameString"
        :reduce="(option) => option._id"
        class="input"
      />
      <div class="cont-btn-edit">
        <div class="btn-green radius small reverse" @click="toggleAdd" v-if="!sending">Cancel</div>
        <div class="btn-green radius small" @click="addStaff" v-if="!sending">Submit</div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
  name: 'AssignStaff',
  props: ['offerId'],
  computed: {
    employees() {
      if (!this.$store.state.company.companyEmployees) {
        return [];
      }
      const allEmployees = this.$store.state.company.companyEmployees.map((employee) => {
        const updatedEmployee = employee.user;
        updatedEmployee.nameString = `${employee.user.name.firstName || ''} ${employee.user.name.lastName || ''}`;
        return updatedEmployee;
      });
      if (this.allStaff.length > 0) {
        return allEmployees.filter(
          (employee) => this.allStaff.findIndex((staffId) => staffId === employee._id) > -1,
        );
      }
      return allEmployees;
    },
    allStaff() {
      return this.$store.getters['company/getOfferById'](this.offerId).staff;
    },
  },
  data() {
    return {
      newStaff: '',
      adding: false,
      sending: false,
    };
  },
  mounted() {
    if (!this.$store.state.company.companyEmployees) {
      this.$store.dispatch('company/getEmployees');
    }
  },

  methods: {
    nameString(name) {
      return `${name.firstName || ''} ${name.lastName || ''}`;
    },
    deleteStaff(staff) {
      this.$store.dispatch('company/unAssignEmployee', { employeeId: staff.user._id, offerId: this.offerId });
    },
    addStaff() {
      this.$store.dispatch('company/assignEmployee', { employeeId: this.newStaff, offerId: this.offerId, role: 'Manager' }).then(() => {
        this.toggleAdd();
        this.sending = false;
      });
    },

    toggleAdd() {
      this.newStaff = '';
      this.adding = !this.adding;
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'assign-staff');
    },

  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.manage-cont{
  position: relative;
  width: 100%;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .contentbox{
    width: calc(100% - 3vh);
  }
  .add-toggle{
    justify-content: center;
    display: flex;
    width: 100%;
    .button{
      width: 40%;
    }
  }
  .staff-entry{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 1vh);
    padding: 0.5vh;
    background-color: #ffffff;
    margin-bottom: 1vh;
    span{
      font-size: $fnt-14;
      width: 60%;
    }
  }
  .add-staff{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .input{
      width: 50%;
    }
    .cont-btn-edit{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

  }
}

</style>
