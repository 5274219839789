<template>
  <div class="offerCompanyEntry radius box-shadow">
    <div class="line"></div>
    <div class="infos">
      <div class="title">
        {{ this.offer.name }}
      </div>
      <div class="infosupp">
        <div class="elem-infosupp contract">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Job Type',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img :src="require('@/assets/pictos/picto_contract_darkpurple.svg')" />
          </div>
          <div class="txt">
            {{ this.offer.contract }}
          </div>
        </div>
        <div class="elem-infosupp location">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Localization',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')" />
          </div>
          <div class="txt">
            {{ this.offer.location }}
          </div>
        </div>
        <div class="elem-infosupp location">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Re;ote Work',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img class="small" :src="require('@/assets/pictos/picto_remote.svg')" />
          </div>
          <div class="txt">
            {{ this.offer.remote }}
          </div>
        </div>
        <div class="elem-infosupp sector">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Field',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img class="small" :src="require('@/assets/pictos/picto_sector.svg')" />
          </div>
          <div class="txt">
            {{ this.offer.sector }}
          </div>
        </div>
        <div class="elem-infosupp salary" v-if="this.offer.salary.frequency != 'Unspecified'">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Salary',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img  :src="require('@/assets/pictos/picto_euro_darkpurple.svg')" />
          </div>
          <div class="txt">
            {{ this.salary }}
          </div>
        </div>
        <div class="elem-infosupp process">
          <div class="picto"
            v-tooltip.top-center="{
              content: 'Process',
              delay: {
                show: 0,
                hide: 0,
              }
            }"
          >
            <img  :src="require('@/assets/pictos/picto_process_darkpurple.svg')" />
          </div>
          <div class="txt">
            {{ this.processName }}
          </div>
        </div>
      </div>
    </div>
    <div class="global-param">
      <div class="param ghost"></div>
      <div class="param" @click="handleOpenParam">
        <img src="@/assets/pictos/picto_param_purple.svg" />
        <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg" />
      </div>
      <div class="cont-param">
        <div class="elem-param"
          v-if="!appStatusPage" @click="applicantStatus">{{ $t("applicantsstatus") }}</div>
        <div class="elem-param" @click="view">{{ $t("viewoffer") }}</div>
        <div class="elem-param" v-if="!editPage" @click="edit">{{ $t("editoffer") }}</div>
        <div class="elem-param" @click="assign">{{ $t("editnotifications") }}</div>
        <div class="elem-param" @click="duplicate">duplicate</div>
        <div class="elem-param delete" @click="deleteOffer">{{ $t("deleteoffer") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignStaff from '@/components/EmployeeComponents/Offer/AssignStaff.vue';

export default {
  name: 'OfferCompanyEntry',
  props: ['offer'],
  computed: {
    salary() {
      const salString = this.offer.salary.currency || '$';
      if (this.offer.salary.amount) {
        return `${salString}${this.offer.salary.amount}`;
      }

      return `${salString}${this.offer.salary.range.min}-${this.offer.salary.range.max}`;
    },
    processName() {
      const name = this.$store.getters['company/processName'](this.offer.process);
      if (name) {
        return name;
      }
      return 'Custom';
    },
    appStatusPage() {
      return this.$router.currentRoute.path.indexOf('application-management') > -1;
    },
    editPage() {
      return this.$router.currentRoute.path.indexOf('job-management') > -1;
    },
  },

  methods: {
    edit() {
      this.$emit('edit-offer-company', this.offer._id);
    },
    deleteOffer() {
      this.$emit('delete-offer-company', this.offer._id);
    },
    view() {
      this.$router.push(`/offer/${this.offer._id}`);
    },
    applicantStatus() {
      this.$store.dispatch('company/setCurrentOfferApplicationId', this.offer._id);
      this.$router.push('/application-management');
    },
    assign() {
      this.$modal.show(
        AssignStaff,
        {
          allStaff: this.offer.staff || [],
          offerId: this.offer._id,
        },
        {
          name: 'assign-staff',
          width: '600px',
          height: '200px',
          scrollable: true,
          adaptive: true,
          classes: ['overflow'],
        },
      );
    },
    handleOpenParam(event) {
      if (event.target.parentNode.classList.contains('open')) {
        event.target.parentNode.classList.remove('open');
      } else {
        document.querySelectorAll('.open').forEach((elem) => {
          elem.classList.remove('open');
        });
        event.target.parentNode.classList.add('open');
      }
    },
    duplicate() {
      this.$store.dispatch('company/duplicateOffer', this.offer._id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.offerCompanyEntry {
  position: relative;
  width: 14vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  cursor: pointer;
  margin: 0;
  padding: 0.5vh;
  padding-top: 1vh;
  border: 3px solid #151515;
  box-sizing: content-box;
  background-color: #ffffff;
  transition: all 0.2s;

  .line {
    width: 110%;
    height: 0.5vh;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    &.radius {
      // border-top-left-radius: 1vh;
      // border-top-right-radius: 1vh;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
    }
  }
  &.radius {
    overflow: hidden;
  }
  .infos {
    text-align: left;
    width: 100%;
    font-size: 1.6vh;
    .name {
      font-size: 1.4vh;
      font-style: italic;
    }
    .title {
      font-family: "MontserratBold";
      font-weight: 600;
      font-size: 2vh;
      color: #8c28e1;
      padding-left: 1vh;
      width: calc(100% - 30px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        font-family: "MontserratRegular";
        font-style: italic;
      }
    }
    .infosupp {
      display: flex;
      flex-direction: row;
      margin-top: 1vh;
      flex-wrap: wrap;
      justify-content: space-between;
      .elem-infosupp {
        position: relative;
        margin-right: 2vh;
        display: flex;
        flex-direction: row;
        width: 40%;
        margin: 0.7vh;
        .picto {
          position: relative;
          width: 1.8vh;
          font-size: 0;
          margin-right: 0.5vh;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 150%;
          }
          .small {
            width: 100% !important ;
          }
        }
        .txt {
          font-size: 10px;
        }
      }
    }
  }
  &:hover {
    .line {
      background-color: $green_color;
    }
  }
  &.selected {
    border: 3px solid #48ffaf;
    width: calc(14vw - 4px);
  }
}
</style>
