<template>
<div class="list-top-offer">
    <div class="title-offers">
      {{ $t("selectajoboffer") }}
    </div>
<splide v-if="!this.loadingOffers" class="offer-list"
  :options="{gap: '1rem',pagination: false, autoWidth: true}"
  >

  <splide-slide v-for="offer in offers"
      :key="offer._id"
      class="flex-display"
      >
    <offer
      :offer="offer"
      :class="{selected: selectable && currentOfferApplicationId === offer._id}"
      @edit-offer-company="(offerId) => handleEdit(offerId)"
      @delete-offer-company="(offerId) => handleDelete(offerId)"
      @click.native="() => selectOffer(offer._id)"
    />
    <div class="applicants-side" v-if="selectable && currentOfferApplicationId === offer._id">
      <div v-for="(applicant, index) in [...offer.applicants].splice(0,3)"
        :key="applicant._id" class="app-pic"
        :class="{overlap: index > 0}">
        <img :src="applicant.user.profilePicture" />
      </div>
      <div  v-if="offer.applicants.length > 3" class="app-pic">
        <span>
        +{{offer.applicants.length -3}}
        </span>
      </div>
    </div>
  </splide-slide>
</splide>
</div>
</template>

<script>
import { mapState } from 'vuex';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import Offer from '@/components/EmployeeComponents/Offer/_offerCompanyEntry.vue';

export default {
  name: 'OfferList',
  components: {
    Offer,
    Splide,
    SplideSlide,
  },
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      init: false,
    };
  },
  computed: {
    ...mapState('company', ['offers', 'loadingOffers', 'currentOfferApplicationId']),
  },

  methods: {
    handleEdit(offerId) {
      this.$store.dispatch('company/setCurrentOfferApplicationId', offerId);
      this.$router.push('/job-management');
    },
    handleDelete(offerId) {
      this.$store.dispatch('company/deleteOffer', offerId);
    },

    selectOffer(offerId) {
      if (this.selectable) {
        this.$store.dispatch('company/setCurrentOfferApplicationId', offerId);
      }
    },
  },
  mounted() {
    this.$store.dispatch('company/getOffers').then(() => {
      this.$emit('initialized');
    });
  },

};
</script>

<style lang="scss">
  @import "@/assets/scss/_splide.scss";
  @import "@/assets/scss/_variables.scss";
  .list-top-offer{
    position: relative;
    width: 100%;
    height: calc(100% - 2vh);
    padding: 1vh;
  }
  .flex-display{
    display: flex;
  }
  .title-offers{
    color: #48ffaf;
    font-family: 'MontserratBold';
    font-weight: 600;
    font-size: 2vh;
  }
  .offer-list{
    width: calc(100% - 2vh);
    display: flex;
    flex-direction: row;
    align-content: center;
    padding-top: 1vh;
  .splide__arrow{
    top: -0.75vh;
    background-color: #48ffaf;
    opacity: 1;
    &.splide__arrow--next{
      right:0;
    }
    &.splide__arrow--prev{
      right:2vw;
      left: auto;
    }
  }
  .applicants-side{
    width: 2vw;
    top: .5vh;
    background-color: #8c28e1;
    height: calc(100% - 2vh);
    position: relative;
    border-top-right-radius: 1vh;
    border-bottom-right-radius: 1vh;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 1vh .2vw 0 .25vw;
    flex-direction: column;
  }
  .app-pic{
    position: relative;
    width: 1.8vw;
    height: 1.8vw;
    overflow: hidden;
    border-radius: 50%;
    background-color: #151515;
    color: white;
    font-size: 2vh;
    line-height: 2vh;
    border: 1px solid white;
    margin-top: -0.9vw;
    &:first-child{
      margin-top: 0;
    }

    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
    }
    span{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      text-align: center;
    }
  }

}
</style>
