var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offerCompanyEntry radius box-shadow"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"infos"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(this.offer.name)+" ")]),_c('div',{staticClass:"infosupp"},[_c('div',{staticClass:"elem-infosupp contract"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
            content: 'Job Type',
            delay: {
              show: 0,
              hide: 0,
            }
          }),expression:"{\n            content: 'Job Type',\n            delay: {\n              show: 0,\n              hide: 0,\n            }\n          }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{attrs:{"src":require('@/assets/pictos/picto_contract_darkpurple.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.offer.contract)+" ")])]),_c('div',{staticClass:"elem-infosupp location"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
            content: 'Localization',
            delay: {
              show: 0,
              hide: 0,
            }
          }),expression:"{\n            content: 'Localization',\n            delay: {\n              show: 0,\n              hide: 0,\n            }\n          }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{attrs:{"src":require('@/assets/pictos/picto_localisation_darkpurple.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.offer.location)+" ")])]),_c('div',{staticClass:"elem-infosupp location"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
            content: 'Re;ote Work',
            delay: {
              show: 0,
              hide: 0,
            }
          }),expression:"{\n            content: 'Re;ote Work',\n            delay: {\n              show: 0,\n              hide: 0,\n            }\n          }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{staticClass:"small",attrs:{"src":require('@/assets/pictos/picto_remote.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.offer.remote)+" ")])]),_c('div',{staticClass:"elem-infosupp sector"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
            content: 'Field',
            delay: {
              show: 0,
              hide: 0,
            }
          }),expression:"{\n            content: 'Field',\n            delay: {\n              show: 0,\n              hide: 0,\n            }\n          }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{staticClass:"small",attrs:{"src":require('@/assets/pictos/picto_sector.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.offer.sector)+" ")])]),(this.offer.salary.frequency != 'Unspecified')?_c('div',{staticClass:"elem-infosupp salary"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
            content: 'Salary',
            delay: {
              show: 0,
              hide: 0,
            }
          }),expression:"{\n            content: 'Salary',\n            delay: {\n              show: 0,\n              hide: 0,\n            }\n          }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{attrs:{"src":require('@/assets/pictos/picto_euro_darkpurple.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.salary)+" ")])]):_vm._e(),_c('div',{staticClass:"elem-infosupp process"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
            content: 'Process',
            delay: {
              show: 0,
              hide: 0,
            }
          }),expression:"{\n            content: 'Process',\n            delay: {\n              show: 0,\n              hide: 0,\n            }\n          }",modifiers:{"top-center":true}}],staticClass:"picto"},[_c('img',{attrs:{"src":require('@/assets/pictos/picto_process_darkpurple.svg')}})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(this.processName)+" ")])])])]),_c('div',{staticClass:"global-param"},[_c('div',{staticClass:"param ghost"}),_c('div',{staticClass:"param",on:{"click":_vm.handleOpenParam}},[_c('img',{attrs:{"src":require("@/assets/pictos/picto_param_purple.svg")}}),_c('img',{staticClass:"hover",attrs:{"src":require("@/assets/pictos/picto_param_purple_full.svg")}})]),_c('div',{staticClass:"cont-param"},[(!_vm.appStatusPage)?_c('div',{staticClass:"elem-param",on:{"click":_vm.applicantStatus}},[_vm._v(_vm._s(_vm.$t("applicantsstatus")))]):_vm._e(),_c('div',{staticClass:"elem-param",on:{"click":_vm.view}},[_vm._v(_vm._s(_vm.$t("viewoffer")))]),(!_vm.editPage)?_c('div',{staticClass:"elem-param",on:{"click":_vm.edit}},[_vm._v(_vm._s(_vm.$t("editoffer")))]):_vm._e(),_c('div',{staticClass:"elem-param",on:{"click":_vm.assign}},[_vm._v(_vm._s(_vm.$t("editnotifications")))]),_c('div',{staticClass:"elem-param",on:{"click":_vm.duplicate}},[_vm._v("duplicate")]),_c('div',{staticClass:"elem-param delete",on:{"click":_vm.deleteOffer}},[_vm._v(_vm._s(_vm.$t("deleteoffer")))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }